import api from '@/apis/BaseApi';
import { LoginModel } from './models/LoginModel';
import { RegisterModel } from './models/RegisterModel';

export default class AccountApi {
  /**
   * Start registeration flow
   *
   * @param username should fit regexp="^[a-zA-Z][a-zA-Z0-9]{5,15}$"
   * @param email
   * @param password
   *
   * Errors:
   * 400 BAD REQUEST: double-check description of parameters format again.
   * 409 CONFLICT: account to be verified is conflict with other accounts.
   */
  static async register(username: string, email: string, password: string): Promise<string> {
    const body = {
      username,
      email,
      password,
    };

    const result = await api.post('account/register', body);
    return result.data.registerId;
  }

  /**
   * Verify a registered account by authorization code
   *
   * @param authCode the authorization code to verify a registered account
   *
   * Errors:
   * 404 NOT FOUND: auth code is not found.
   * 409 CONFLICT: account to be verified is conflict with other accounts.
   */
  static async verify(authCode: string): Promise<void> {
    const body = {
      authCode,
    };

    await api.post('account/verify', body);
  }

  static async getRegisterInfo(registerId: string): Promise<RegisterModel> {
    const result = await api.get(`account/register/${registerId}`);
    return result.data.registerInfo;
  }

  static async resend(registerId: string): Promise<void> {
    const body = {};

    await api.post(`account/register/${registerId}`, body);
  }

  static async login(identifier: string, password: string): Promise<LoginModel> {
    const body = {
      identifier,
      password,
    };

    const result = await api.post('account/login', body);
    return result.data;
  }
}
