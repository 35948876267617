














































import Vue from 'vue';
import AccountApi from '@/apis/AccountApi';

enum VerificationState {
  INIT,
  PENDING,
  SUCCESS,
  FAILURE,
}

export default Vue.extend({
  components: {
  },
  data() {
    return {
      VerificationState,
      state: VerificationState.INIT,
    };
  },
  async mounted() {
    const authCode = this.$route.query.authCode as string;
    if (authCode) {
      this.state = VerificationState.PENDING;
      try {
        await AccountApi.verify(authCode);
        this.state = VerificationState.SUCCESS;
      } catch (error) {
        this.state = VerificationState.FAILURE;
      }
    } else {
      // authCode not found
      this.state = VerificationState.FAILURE;
      this.$router.push('/login');
    }
  },
  methods: {

  },
});
